import { Heading, Collection, Card, View, Flex, Badge, Divider } from "@aws-amplify/ui-react";
import { MdSensors } from 'react-icons/md';
import Tooltip from '@mui/material/Tooltip';
import { Typography, Container } from "@mui/material";

import { useEffect, useState } from "react";

import { API } from "aws-amplify";

import DeviceStatusInfo from "../interfaces/DeviceStatusInfo";

export default function DeviceStatus() {

  const [items, setItems] = useState<DeviceStatusInfo[]>([]);

  useEffect(() => {
    const updateStatus = async () => {
      let response: DeviceStatusInfo[];
      try {
        response = await API.get("FyrCloud API", `/devices/`, []);
      } catch (error) {
        console.log(error);
        response = [];
      }
      setItems(response);
    };

    // Execute once immediately and then via interval
    updateStatus();
    const interval = setInterval(async () => {
      await updateStatus();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <View id="device-status">
        <Typography variant="h1"
          gutterBottom
          sx={{
            paddingTop: "2%",
            alignSelf: 'flex-start',
            width: '100%',
            textAlign: 'center',
            fontSize: {
              xs: '1.5rem', // for extra-small devices
              sm: '2rem', // for small devices
              md: '2.5rem', // for medium devices
              lg: '3rem', // for large devices
              xl: '3.5rem' // for extra-large devices
            }
          }}>
          Device Status
        </Typography>
        <br></br>
        <Collection
          items={items}
          type="list"
          direction="row"
          gap="20px"
          wrap="wrap"
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}>
          {(item, index) => (
            <Card
              key={index}
              borderRadius="medium"
              maxWidth="20rem"
              variation="outlined"
            >
              <Flex alignItems='center' justifyContent='center'>
                <MdSensors style={{ width: '50%', height: 'auto' }} />
              </Flex>
              <View padding="xs">
                <Flex>
                  {item.connectivity.connected ? (
                    <Badge backgroundColor='green'>
                      online
                    </Badge>
                  ) : (
                    <Tooltip title={"disconnected since " + (new Date(item.connectivity.timestamp)).toISOString()}>
                      <Badge backgroundColor='red'>
                        offline
                      </Badge>
                    </Tooltip>
                  )}
                  <Badge backgroundColor='grey'>
                    {item.context}
                  </Badge>
                </Flex>
                <Flex alignContent='center' justifyContent='center'>
                  <Badge backgroundColor='grey'>
                    {item.location}
                  </Badge>
                </Flex>
                <Divider padding="xs" />
                <Heading textAlign="center" padding="medium">{item.id}</Heading>
              </View>
            </Card>
          )}
        </Collection>
      </View>
    </Container>
  );
}
