import React, { useState } from 'react';
import { Container, Grid, TextField, Typography, Button, Alert, Select, MenuItem, FormControl, InputLabel, Card, CardContent } from '@mui/material';
import { API } from 'aws-amplify';
import SearchIcon from '@mui/icons-material/Search';

function formatResponse(response: any) {

    let formatted: Array<string> = [];
    const stack: [string, any][] = [['', response]];

    while (stack.length > 0) {
      const poppedValue = stack.pop();

      if (poppedValue !== undefined) {
        const [prefix, currentObj] = poppedValue;
        let separator = '.';
        if (prefix === '') {
          separator = '';
        }
        Object.entries(currentObj).forEach(([key, value]) => {
          if (typeof value === 'object' && value !== null) {
            stack.push([`${prefix}${separator}${key}`, value]);
          } else {
            let displayValue = value;
            // Check if the current key is 'timestamp'
            if (key === 'timestamp') {
              const timestamp = Number(value);
              if (!isNaN(timestamp)) {
                displayValue = new Date(timestamp).toLocaleString(); // Convert timestamp to local date string
              }
            }
            formatted = [...formatted, `${prefix}${separator}${key}: ${displayValue}`];
          }
        });
      }
    }

    return formatted;
}

export default function Search() {
  const [searchValue, setSearchValue] = useState("");
  const [queryType, setQueryType] = useState("saons");
  const [myArray, setMyArray] = useState<string[][]>([]);
  const [isAlertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const elementRoot = myArray.map((elem) => (
    <Alert severity="info" style={{ marginTop: "2%" }}>
      {
      elem.map((item, index) => (
        <Typography key={index} variant="body1" style={{ textTransform: "capitalize" }}>
          {item}
        </Typography>
      ))
      }
    </Alert>
  )
);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent default form submit action
    setAlertVisible(false);
    setMyArray([]);
    let response : Array<any>;
    if (searchValue === '') {
      return;
    }
    try {
      response = await API.get("FyrCloud API", `/${queryType}/${searchValue}`, []);
    } catch (error) {
      setAlertMessage(error instanceof Error ? error.message : 'Unknown error happened');
      setAlertVisible(true);
      return;
    }

    if (Object.entries(response).length === 0) {
      setAlertMessage('No result found');
      setAlertVisible(true);
      return;
    }

    setMyArray(response.map((elem) => formatResponse(elem)));
  }

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h1"
        gutterBottom
        sx={{
          paddingTop: "2%",
          paddingBottom: "2%",
          alignSelf: 'flex-start',
          width: '100%',
          textAlign: 'center',
          fontSize: {
            xs: '1.5rem', // for extra-small devices
            sm: '2rem', // for small devices
            md: '2.5rem', // for medium devices
            lg: '3rem', // for large devices
            xl: '3.5rem' // for extra-large devices
          }
        }}>
        Search
      </Typography>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Grid item xs={12}>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card elevation={3}>
            <CardContent>
              {isAlertVisible && (
                <Alert severity="error" onClose={() => setAlertVisible(false)}>
                  {alertMessage}
                </Alert>
              )}

              <form onSubmit={onSubmit}>
                <Grid container direction="column" spacing={2}>
                  <Grid item xs={12}>
                    <FormControl variant="filled" fullWidth>
                      <InputLabel>Type</InputLabel>
                      <Select
                        value={queryType}
                        onChange={(e) => setQueryType(e.target.value)}
                      >
                        <MenuItem value="saons">SAON</MenuItem>
                        <MenuItem value="tpms">TPMS</MenuItem>
                        <MenuItem value="chassis">Chassis</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Search"
                      variant="outlined"
                      value={searchValue}
                      onChange={onChange}
                      fullWidth
                      sx={{
                        width: { xs: '100%', sm: '400px', md: '500px' },
                      }}
                      InputProps={{
                        endAdornment: (
                          <SearchIcon color="action" />
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      startIcon={<SearchIcon />}
                      sx={{
                        '&:hover': {
                          textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', // Optional text shadow for depth
                          transition: 'transform 0.3s ease-in-out', // For animation
                          transform: 'scale(1.01)', // Slightly enlarge on hover
                        },
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          {elementRoot}
        </Grid>
      </Grid>
    </Container>
  );
}
