import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";

function Login() {
  const navigate = useNavigate();
  const { route } = useAuthenticator((context) => [context.route]);

  useEffect(() => {
    if (route === "authenticated") {
      navigate("/");
    }
  }, [navigate, route]);

  return (
    <Container maxWidth={false} sx={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: "/public/background.png",
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    }}>
      <Paper elevation={10} sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 4,
        borderRadius: 2,
        minWidth: 300,
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(5px)',
        border: '1px solid rgba(255, 255, 255, 0.3)'
      }}>
        <Box
          component="img"
          sx={{
            height: 80,
            mb: 2,
          }}
          src="/logo.svg"
        />
        <Button variant="contained" onClick={() => Auth.federatedSignIn()} sx={{
          mt: 2,
          bgcolor: 'primary.main',
          ':hover': {
            bgcolor: 'primary.dark',
          },
          padding: '8px 30px',
          textTransform: 'none',
          boxShadow: '0px 2px 2px 1px rgba(0, 0, 0, 0.2)'
        }}>
          Login
        </Button>
      </Paper>
    </Container>
  );
}

export default Login;
