import { useAuthenticator } from "@aws-amplify/ui-react";
import Login from "./Login";

export default function RequireAuth({ children }: any) {
  const { route } = useAuthenticator((context) => [context.route]);
  console.log(route);
  /* Avoid briefly flashing the login screen while the authenticator is still working in the background */
  if (route === "idle") {
    return;
  }
  if (route !== "authenticated") {
    return <Login/>
  }
  return children;
}
