import { Auth } from "aws-amplify";

const redirects: { [name: string]: string }  = {
  'localhost': 'http://localhost:3000',
  'main.d1yn3aeabgufej.amplifyapp.com': 'https://main.d1yn3aeabgufej.amplifyapp.com',
  'app.fyrqom.com': 'https://app.fyrqom.com',
  'app.fyrqom-staging.com': 'https://app.fyrqom-staging.com'
};

export const awsConfig = {
  Auth: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_LOXdeVg2R',
    userPoolWebClientId: '74h8tnpf2dl4uctn631udliihh',
    mandatorySignIn: true,
    oauth: {
      domain: 'auth.app.fyrqom.com',
      redirectSignIn: redirects,
      redirectSignOut: redirects,
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: 'FyrCloud API',
        endpoint: 'https://api.app.fyrqom.com',
        custom_header: async () => {
            return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        },
      },
    ]
  }
};
