import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Amplify } from "aws-amplify";
import { awsConfig } from "./awsconfig";

import { ThemeProvider, createTheme } from '@mui/material/styles';


const getRedirect = (map: { [name: string]: string }) => {
  const redirect = map[window.location.hostname];
  if (redirect !== undefined) {
    return redirect;
  }
  return '';
};

const updatedAwsConfig = {
  ...awsConfig,
  Auth: {
    ...awsConfig.Auth,
    oauth: {
      ...awsConfig.Auth.oauth,
      redirectSignIn: getRedirect(awsConfig.Auth.oauth.redirectSignIn),
      redirectSignOut: getRedirect(awsConfig.Auth.oauth.redirectSignOut),
    },
  },
};

Amplify.configure(updatedAwsConfig);

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});


ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById("root") as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
