import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";

import Dashboard from "./components/Dashboard";
import Explore from "./components/Explore";
import Search from "./components/Search";
import DeviceStatus from "./components/DeviceStatus";
import NavBar from "./components/NavBar";
import RequireAuth from "./components/RequireAuth";

import Container from '@mui/material/Container';

import "./App.css";
import "@aws-amplify/ui-react/styles.css";

// TODO: still required by login - should be removed
import "bootstrap/dist/css/bootstrap.min.css";

// Login router heavily based on
// https://ui.docs.amplify.aws/react/guides/auth-protected

// Navigation inspired by
// https://stackoverflow.com/questions/72278370/how-to-add-navbar-and-a-sidebar-inside-the-react-router-v6-routes

function App() {
  return (
    <Authenticator.Provider>
      <BrowserRouter>
        <RequireAuth>
          <Container id="rootContainer">
              <NavBar/>
              <Routes>
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="/dashboard" index element={ <Dashboard /> } />
                <Route path="/explore" index element={ <Explore/> } />
                <Route path="/search" index element={ <Search/> } />
                <Route path="/device-status" element={ <DeviceStatus /> } />
              </Routes>
          </Container>
        </RequireAuth>
      </BrowserRouter>
    </Authenticator.Provider>
  );
}

export default App;
