import { Container, Grid, Typography, Button, Paper, FormControl, InputLabel, Select, MenuItem, CardActions, CardContent, Card, CardHeader, ListItemText, Checkbox } from '@mui/material';
import { useState, useEffect, useRef } from "react";
import dayjs from 'dayjs';
import "dayjs/locale/de";
import "dayjs/locale/be";
import "dayjs/locale/en";
import "dayjs/locale/sk";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from "@mui/x-date-pickers";
import { CSVLink } from "react-csv";
import { API } from "aws-amplify";
import TPMSReading from "../interfaces/TPMSReading";
import DeviceStatusInfo from "../interfaces/DeviceStatusInfo";
import { DataGrid, GridToolbarContainer, useGridApiRef, GridColDef } from '@mui/x-data-grid';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';

const QUERY_LIMIT = 1000;

interface GetDataResult {
  nextCursor: number,
  data: TPMSReading[]
};

interface LocationOption {
  value: string;
}

interface ContextOption {
  value: string;
}
// should keep the same JS reference - leave outside in outer module scope
function getRowId(row: TPMSReading) {
  return row.id + '-' + row.timestamp;
}

function toHumanReadableDateTime(timestamp: number) {
  return (new Date(timestamp)).toLocaleString(navigator.language);
}

function toHumanReadableDate(timestamp: number) {
  return (new Date(timestamp)).toLocaleDateString(navigator.language);
}

// TODO: this is really hacky
const dayjsLocale = navigator.language.split('-')[0];
dayjs.locale(dayjsLocale);

export default function Explore() {
  const apiRef = useGridApiRef();
  const devices = useRef<Record<string, DeviceStatusInfo>>({});
  const locations = useRef<Record<string, Set<string>>>({});
  const mapPageToNextCursor = useRef<{ [page: number]: number }>({});
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  const [optionLocations, setOptionLocations] = useState<LocationOption[]>([]);
  const [optionContexts, setOptionContexts] = useState<ContextOption[]>([]);
  const [rows, setRows] = useState<TPMSReading[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const csvLinkRef = useRef<any>();
  const [csvFilename, setCsvFilename] = useState('');
  const [csvExport, setCsvExport] = useState<TPMSReading[]>([]);
  const [csvButtonDisabled, setCsvButtonDisabled] = useState(false);
  const columns: GridColDef<TPMSReading>[] = [
    { field: 'reference', filterable: false },
    { field: 'id', filterable: false },
    {
      field: 'timestamp',
      type: 'dateTime',
      valueFormatter: (value) => toHumanReadableDateTime(value),
      filterable: false,
    },
    { field: 'context', filterable: false },
    { field: 'location', filterable: false },
    { field: 'pressure', filterable: false },
    { field: 'referencePressure', headerName: 'pressure (reference)', filterable: false },
    { field: 'temperature', filterable: false },
    { field: 'battery', filterable: false }
  ]

  const getDeviceInformation = async () => {
    let response: DeviceStatusInfo[];
    try {
      response = await API.get("FyrCloud API", `/devices/`, []);
    } catch (error) {
      console.log(error);
      response = [];
    }
    // get contexts from api
    const contexts = response.map((device) => ({ value: device.context }));
    const uniqueContextObjects = Array.from(new Set(contexts.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
    setOptionContexts(uniqueContextObjects);

    // get locations from api
    const locationObjects = response.map((device) => ({ value: device.location }));
    const uniqueLocationObjects = Array.from(new Set(locationObjects.map(obj => JSON.stringify(obj))))
      .map(str => JSON.parse(str));
    setOptionLocations(uniqueLocationObjects);
    // build easy lookup objects
    // device -> location
    // location -> devices
    response.forEach((item) => {
      devices.current[item.id] = item;
      let locs = locations.current[item.location];
      if (locs === undefined) {
        locs = new Set();
        locations.current[item.location] = locs;
      }
      locs.add(item.id);
    });
  };

  const getData = async (since: number, until: number, context: string[], location: string[], limit: number, convertHumanReadable = false): Promise<GetDataResult | null> => {
    let response: TPMSReading[];
    let context_query = context == null || context.length === 0 ? "" : `&context=${context.join(",")}`;
    let sender_query = '';
    if (location !== null && location.length > 0) {
      let senders = location.map((loc) => Array.from(locations.current[loc]));
      sender_query = `&sender=${senders.join(",")}`;
    }
    //console.log(context_query);
    //console.log(sender_query);
    try {
      response = await API.get(
        "FyrCloud API",
        `/tpms/?since=${since}&until=${until}&limit=${limit}&scan_forward=true${context_query}${sender_query}`,
        []
      ) as TPMSReading[];
    } catch (error) {
      console.log(error);
      return null;
    }

    if (response.length === 0) {
      // no data in range -> keep using same start cursor
      return { nextCursor: since, data: [] };
    }

    response.map((item) => {
      if (convertHumanReadable) {
        item.datetime = toHumanReadableDateTime(item.timestamp);
      }
      item.location = devices.current[item.sender].location;
      return item;
    });

    let nextCursor = response[response.length - 1].timestamp;

    return { nextCursor: nextCursor, data: response };
  };

  // filter default values
  const defaultFilters: CustomFilters = {
    context: [],
    location: [],
    start_date: dayjs().startOf('week').valueOf(),
    end_date: dayjs().startOf('day').add(1, 'day').valueOf(),
  };
  const [customFilters, setCustomFilters] = useState<CustomFilters>(defaultFilters);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      // TODO: this is messy and has overhead - should be handled either on server-side or using a proper dependency
      await getDeviceInformation();

      let cursor = mapPageToNextCursor.current[paginationModel.page - 1];
      let result = await getData(
        cursor !== undefined ? cursor : customFilters["start_date"],
        customFilters["end_date"],
        customFilters["context"],
        customFilters["location"],
        paginationModel.pageSize
      );
      if (result === null) {
        console.log('error -> no data fetched')
        setIsLoading(false);
        setRows([]);
        return;
      }

      // save cursor for pagination
      mapPageToNextCursor.current[paginationModel.page] = result.nextCursor;

      // data update process
      setRows(result.data);

      setIsLoading(false);

    })();
  }, [paginationModel.page, paginationModel.pageSize, customFilters]);

  // see https://stackoverflow.com/a/71544949 - similar to the implementation of MUI's CSV exporter
  // see https://github.com/mui/mui-x/issues/4586
  async function exportCSVFile() {
    let since = customFilters["start_date"];
    let until = customFilters["end_date"];
    let limit = QUERY_LIMIT;

    let data: TPMSReading[] = [];

    // sneakily use loading animation from datagrid
    setIsLoading(true);
    setCsvButtonDisabled(true);

    // set filename for export
    let filename = `fyrcloud-export-${toHumanReadableDate(since)}-${toHumanReadableDate(until)}.csv`;
    setCsvFilename(filename);

    let result;
    do {
      result = await getData(since, until, customFilters["context"], customFilters["location"], limit, true);
      if (result === null) {
        throw Error('unable to get data');
      }
      since = result.nextCursor;
      data = data.concat(result.data);
    } while (result.data.length === limit);

    // Deduplicate data
    let uniqueData = deduplicateData(data);
    setCsvExport(uniqueData);
    setIsLoading(false);
  }

  // Deduplication function based on 'id', 'location', and 'timestamp'
  function deduplicateData(data: TPMSReading[]): TPMSReading[] {
    const unique: { [key: string]: TPMSReading } = {};
    data.forEach(item => {
      const uniqueKey = `${item.id}-${item.location}-${item.timestamp}`;
      unique[uniqueKey] = item;
    });
    return Object.values(unique);
  }

  useEffect(() => {
    if (csvExport.length > 0) {
      setCsvButtonDisabled(false);
      // had to be moved to auto-resizing handler - see comment there
      //setIsLoading(false);
      csvLinkRef.current.link.click();
    }
  }, [csvExport]);


  function CSVExportToolbar() {
    return (
      <GridToolbarContainer>
        <Button onClick={exportCSVFile} disabled={csvButtonDisabled}>
          Export CSV
        </Button>
        <CSVLink
          headers={columns.map((item) => item.field === 'timestamp' ? 'datetime' : item.field)}
          data={csvExport}
          style={{
            display: 'none'
          }}
          filename={csvFilename}
          ref={csvLinkRef}
          target="#"
        />
      </GridToolbarContainer>
    );
  }

  const [showFilters, setShowFilters] = useState(false);

  interface CustomFilters {
    context: string[];
    location: string[];
    start_date: number;
    end_date: number;
  }

  const handleCustomFilterChange = (field: any, value: any) => {
    setCustomFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
    setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });
  };

  useEffect(() => {
    if (apiRef.current) {
      apiRef.current.autosizeColumns();
    }
  }, [apiRef, rows]);

  const resetTable = () => {
    setCustomFilters(defaultFilters);
    // Keep table page size
    setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h1"
        gutterBottom
        sx={{
          paddingTop: "2%",
          paddingBottom: "2%",
          alignSelf: 'flex-start',
          width: '100%',
          textAlign: 'center',
          fontSize: {
            xs: '1.5rem', // for extra-small devices
            sm: '2rem', // for small devices
            md: '2.5rem', // for medium devices
            lg: '3rem', // for large devices
            xl: '3.5rem' // for extra-large devices
          }
        }}>
        Explore Data
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button variant="contained" onClick={() => setShowFilters(!showFilters)}>
            {showFilters ? 'Hide Filters' : 'Show Filters'} {showFilters ? <MdArrowDropUp /> : <MdArrowDropDown />}
          </Button>
        </Grid>
        {showFilters && (
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Filter Options" />
              <CardContent>
                <Grid container spacing={2}>
                  {/* // Context Filter */}
                  <Grid item xs={12} sm={6} lg={6}>
                    <FormControl variant="filled" fullWidth>
                      <InputLabel id="context-label">Context</InputLabel>
                      <Select
                        labelId="context-label"
                        id="context"
                        multiple
                        value={customFilters["context"]}
                        onChange={(e) => { handleCustomFilterChange('context', e.target.value); }}
                        renderValue={(selected: string[]) => selected.join(', ')}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 224,
                              width: 250,
                            },
                          },
                        }}
                      >
                        {optionContexts.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            <Checkbox checked={customFilters["context"].indexOf(option.value) > -1} />
                            <ListItemText primary={option.value} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* // Location Filter */}
                  <Grid item xs={12} sm={6} lg={6}>
                    <FormControl variant="filled" fullWidth>
                      <InputLabel id="location-label">Location</InputLabel>
                      <Select
                        labelId="location-label"
                        id="location"
                        multiple
                        value={customFilters["location"]}
                        onChange={(e) => { handleCustomFilterChange('location', e.target.value); }}
                        renderValue={(selected: string[]) => selected.join(', ')}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 224,
                              width: 250,
                            },
                          },
                        }}
                      >
                        {optionLocations.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            <Checkbox checked={customFilters["location"].indexOf(option.value) > -1} />
                            <ListItemText primary={option.value} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* // Daterange Filter */}
                  <Grid item xs={12} sm={6} lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjsLocale}>
                      <DatePicker
                        label="From"
                        value={customFilters.start_date ? dayjs(customFilters.start_date) : null}
                        onChange={(e) => { handleCustomFilterChange('start_date', e?.valueOf() ?? dayjs().startOf('day').valueOf()); }}
                      />
                      <DatePicker
                        label="To"
                        value={customFilters.end_date ? dayjs(customFilters.end_date) : null}
                        onChange={(e) => { handleCustomFilterChange('end_date', e?.valueOf() ?? dayjs().startOf('day').add(1, 'day').valueOf()); }} />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Button size="small" onClick={resetTable}>
                  Reset
                </Button>
              </CardActions>
            </Card>
          </Grid>
        )}
        <Grid item xs={12} style={{ width: '100%' }}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 650 }}>
            <DataGrid
              apiRef={apiRef}
              getRowId={getRowId}
              columns={columns}
              rows={rows}
              autosizeOnMount={true}
              disableColumnFilter={true}
              disableColumnSorting={true}
              disableColumnMenu={true}
              slots={{ toolbar: CSVExportToolbar }}
              loading={isLoading}
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              paginationMode="server"
              rowCount={Number.MAX_VALUE}
              sx={{
                '& .MuiDataGrid-main': {
                  alignItems: 'center',
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: 'primary.light',
                  color: 'primary.black',
                },
                '.MuiTablePagination-displayedRows': {
                  display: 'none',
                },
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container >
  );
}