import {
  Container, Grid, Typography, Button, Card, CardActions, CardContent, CardHeader,
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Slide, useTheme
} from '@mui/material';
import { Dropdown } from "./Dropdown";
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import { useState, useEffect, useRef } from "react";
import { API } from "aws-amplify";
import TPMSReading from "../interfaces/TPMSReading";
import DeviceStatusInfo from "../interfaces/DeviceStatusInfo";

const DISPLAY_LIMIT = 12;

// filtering inspired by https://codesandbox.io/s/table-filtering-with-mui-by26g

export default function Dashboard() {
  const devices = useRef<DeviceStatusInfo[]>([]);

  const [lastTimestamp, setLastTimestamp] = useState<Number>(0);
  const [rows, setRows] = useState<TPMSReading[]>([]);

  const [showFilters, setShowFilters] = useState(false);

  const [optionDeviceIds, setOptionDeviceIds] = useState<String[]>([]);
  const [optionContexts, setOptionContexts] = useState<String[]>([]);
  const [optionLocations, setOptionLocations] = useState<String[]>([]);

  const [filterDeviceIds, setFilterDeviceIds] = useState<String[]>([]);
  const [filterContexts, setFilterContexts] = useState<String[]>([]);
  const [filterLocations, setFilterLocations] = useState<String[]>([]);

  const filterIfNotEmpty = (values: any[],
    keyFn: (key: any) => any, filter: any[]) => {
    if (filter.length === 0) {
      return values;
    }
    return values.filter((value) => filter.includes(keyFn(value)));
  };

  const filterLocationDeviceIds =
    filterIfNotEmpty(devices.current, (device) => device.location, filterLocations).map((device) => device.id);

  const resetFilters = () => {
    setFilterDeviceIds([]);
    setFilterContexts([]);
    setFilterLocations([]);
    resetTable();
  };
  const resetTable = () => {
    setLastTimestamp(0);
    setRows([]);
  };

  const getDeviceInformation = async () => {
    let response: DeviceStatusInfo[];
    try {
      response = await API.get("FyrCloud API", `/devices/`, []);
    } catch (error) {
      console.log(error);
      response = [];
    }
    devices.current = response;

    /* Sort fyrbox device IDs numerically */
    const collator = new Intl.Collator([], { numeric: true });
    const deviceIds = response.map((device) => device.id).sort((a, b) => collator.compare(a, b));
    setOptionDeviceIds(deviceIds)

    /* Generate collection of all unique values */
    const contexts = Array.from(new Set(response.map((device) => device.context)));
    setOptionContexts(contexts);
    const locations = Array.from(new Set(response.map((device) => device.location)));
    setOptionLocations(locations);
  };

  const updateTable = async (lastTimestamp: Number) => {

    let response: TPMSReading[];
    try {
      response = await API.get(
        "FyrCloud API",
        `/tpms/?since=${lastTimestamp}&limit=${DISPLAY_LIMIT}`,
        []
      ) as TPMSReading[];
    } catch (error) {
      console.log(error);
      return;
    }

    /* 
      Filter response according to given criteria.

      TODO: Maybe move to backend?
    */
    response = filterIfNotEmpty(response, (reading) => reading.sender, filterLocationDeviceIds);
    response = filterIfNotEmpty(response, (reading) => reading.sender, filterDeviceIds);
    response = filterIfNotEmpty(response, (reading) => reading.context, filterContexts);

    if (response.length === 0) {
      return;
    }

    /* 
      Merge response with existing values displayed in table 
      and schedule all rows that shall not be displayed anymore
      for dismounting. 
    */
    response = [...response, ...rows];
    response.slice(DISPLAY_LIMIT).map((elem: TPMSReading) => {
      elem.visible = false;
      return elem;
    });

    /*
      Extract the timestamp from the first and latest element
      and increment it (by a millisecond) to only display future elements
      after this timestamp.
    */
    setLastTimestamp(response[0]?.timestamp + 1);

    /* Display new responses. */
    setRows(response);
  };

  useEffect(() => {
    getDeviceInformation();
  }, []);

  useEffect(() => {
    // Reload all data initially and immediately when filter is changed
    updateTable(0);
    // eslint-disable-next-line
  }, [filterDeviceIds, filterContexts, filterLocations]);

  useEffect(() => {
    const interval = setInterval(async () => {
      await updateTable(lastTimestamp);
    }, 10000);

    return () => {
      clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [rows, lastTimestamp]);

  const theme = useTheme();

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h1"
        gutterBottom
        sx={{
          paddingTop: "2%",
          paddingBottom: "2%",
          alignSelf: 'flex-start',
          width: '100%',
          textAlign: 'center',
          fontSize: {
            xs: '1.5rem', // for extra-small devices
            sm: '2rem', // for small devices
            md: '2.5rem', // for medium devices
            lg: '3rem', // for large devices
            xl: '3.5rem' // for extra-large devices
          }
        }}>
        Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button variant="contained" onClick={() => setShowFilters(!showFilters)}>
            {showFilters ? 'Hide Filters' : 'Show Filters'} {showFilters ? <MdArrowDropUp /> : <MdArrowDropDown />}
          </Button>
        </Grid>
        {showFilters && (
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Filter Options" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} lg={4}>
                    <Dropdown
                      id="device"
                      value={filterDeviceIds}
                      onChange={(e: any) => { resetTable(); setFilterDeviceIds(e.target.value); }}
                      label="Device"
                      options={optionDeviceIds}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <Dropdown
                      id="context"
                      value={filterContexts}
                      onChange={(e: any) => { resetTable(); setFilterContexts(e.target.value); }}
                      label="Context"
                      options={optionContexts}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <Dropdown
                      id="location"
                      value={filterLocations}
                      onChange={(e: any) => { resetTable(); setFilterLocations(e.target.value); }}
                      label="Location"
                      options={optionLocations}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Button size="small" onClick={resetFilters}>
                  Reset
                </Button>
              </CardActions>
            </Card>
          </Grid>
        )}
        <Grid item xs={12}>
          <Paper sx={{ width: '100%', overflowX: 'auto' }}>
            <TableContainer component={Paper}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.action.hover, color: theme.palette.common.black }}>Reference</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.action.hover, color: theme.palette.common.black }}>TPMS ID</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.action.hover, color: theme.palette.common.black }}>Timestamp</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.action.hover, color: theme.palette.common.black }}>Battery</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.action.hover, color: theme.palette.common.black }}>Temperature</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.action.hover, color: theme.palette.common.black }}>Pressure</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.action.hover, color: theme.palette.common.black }}>Pressure (reference)</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.action.hover, color: theme.palette.common.black }}>Device</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.action.hover, color: theme.palette.common.black }}>Context</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <Slide
                      key={row.timestamp + ' - ' + row.id}
                      direction="right"
                      in={row.visible !== false}
                      timeout={750}
                      unmountOnExit
                      onEntered={(node) => node.style.backgroundColor = ''}
                      onExit={(node) => node.style.backgroundColor = 'red'}
                      onExited={() =>
                        setRows((rows) =>
                          rows.filter(r =>
                            !((r.id === row.id) && (r.timestamp === row.timestamp))
                          )
                        )
                      }
                      style={{
                        backgroundColor: 'green'
                      }}>
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row.reference}
                        </TableCell>
                        <TableCell>{row.id !== undefined ? row.id : ""}</TableCell>
                        <TableCell>{(new Date(row.timestamp)).toLocaleString(navigator.language)}</TableCell>
                        <TableCell>{row.battery !== undefined ? row.battery + "%" : ""}</TableCell>
                        <TableCell>{row.temperature !== undefined ? row.temperature + "°C" : ""}</TableCell>
                        <TableCell>{row.pressure !== undefined ? row.pressure + " bar" : ""}</TableCell>
                        <TableCell>{row.referencePressure !== undefined ? row.referencePressure + " bar" : ""}</TableCell>
                        <TableCell>{row.sender}</TableCell>
                        <TableCell>{row.context}</TableCell>
                      </TableRow>
                    </Slide>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}