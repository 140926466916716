import * as React from 'react';
import { AppBar, Box, Button, Grid, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography, Avatar, useMediaQuery, Theme } from '@mui/material';
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { MdOutlineDashboard, MdOutlineTableView, MdLogout, MdSatelliteAlt, MdOutlineSearch, MdMenu } from 'react-icons/md';

export default function NavBar() {
  const { route, signOut } = useAuthenticator((context) => [context.route, context.signOut]);
  const [currentUser, setCurrentUser] = React.useState<any>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  // Use useMediaQuery hook to check for mobile screen sizes
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  useEffect(() => {
    if (route === "authenticated") {
      Auth.currentUserInfo().then((user) => {
        setCurrentUser(user);
      });
    }
  }, [route]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // Toggle the mobile menu
  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };

  // Render navigation links for mobile menu
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMenuAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(mobileMenuAnchorEl)}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleMobileMenuClose}>
        <NavLink className="menu-option" to="/dashboard">
          <Button style={{ color: 'inherit' }}> <MdOutlineDashboard /> Dashboard </Button>
        </NavLink>
      </MenuItem>
      <MenuItem onClick={handleMobileMenuClose}>
        <NavLink className="menu-option" to="/explore">
          <Button style={{ color: 'inherit' }}> <MdOutlineTableView /> Explore </Button>
        </NavLink>
      </MenuItem>
      <MenuItem onClick={handleMobileMenuClose}>
        <NavLink className="menu-option" to="/search">
          <Button style={{ color: 'inherit' }}> <MdOutlineSearch /> Search </Button>
        </NavLink>
      </MenuItem>
      <MenuItem onClick={handleMobileMenuClose}>
        <NavLink className="menu-option" to="/device-status">
          <Button style={{ color: 'inherit' }}><MdSatelliteAlt /> Device Status</Button>
        </NavLink>
      </MenuItem>
    </Menu>
  );

  const renderUserSettings = (
    <Box display="flex" alignItems="center">
      <Tooltip title="User settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem key="user" style={{ pointerEvents: 'none' }}>
          <Typography textAlign="center">
            Signed in as
            <br />
            {currentUser?.attributes?.email}
          </Typography>
        </MenuItem>
        <MenuItem key="logout" onClick={signOut}>
          <MdLogout style={{ marginRight: 2 }} />
          <Typography color="red">Logout</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );

  return (
    <AppBar position="static" sx={{ backgroundColor: 'white' }}>
      <Toolbar>
        {isMobile ? (
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={3} md={3}>
              <IconButton
                size="large"
                edge="start"
                color="default"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={handleMobileMenuOpen}>
                <MdMenu />
              </IconButton>
              {renderMobileMenu}
            </Grid>
            <Grid item xs={2.5} md={1.5}>
              <Box display="flex" justifyContent="space-around" alignItems="center" sx={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
                <img src="/logo.svg" alt="description" style={{ maxWidth: '85%', maxHeight: '100%' }} />
              </Box>
            </Grid>
            <Grid item xs={3} md={3} container justifyContent="flex-end">
              {renderUserSettings}
            </Grid>
          </Grid>
        ) : (
          <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
            <Grid item lg={1.5}>
              <Box display="flex" justifyContent="space-around" alignItems="center" sx={{ paddingTop: '1vh', paddingBottom: '1vh' }}>
                <img src="/logo.svg" alt="description" style={{ maxWidth: '85%', maxHeight: '100%' }} />
              </Box>
            </Grid>
            <Grid item container lg justifyContent="flex-start">
              <NavLink className="menu-option" to="/dashboard">
                <Button style={{ color: 'inherit' }}> <MdOutlineDashboard /> Dashboard </Button>
              </NavLink>
              <NavLink className="menu-option" to="/explore">
                <Button style={{ color: 'inherit' }}> <MdOutlineTableView /> Explore </Button>
              </NavLink>
              <NavLink className="menu-option" to="/search">
                <Button style={{ color: 'inherit' }}> <MdOutlineSearch /> Search </Button>
              </NavLink>
              <NavLink className="menu-option" to="/device-status">
                <Button style={{ color: 'inherit' }}><MdSatelliteAlt /> Device Status</Button>
              </NavLink>
            </Grid>
            <Grid item container lg={1} justifyContent="flex-end">
              {renderUserSettings}
            </Grid>
          </Grid>
        )}
      </Toolbar>
    </AppBar >
  );
}
