import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

export const Dropdown = ({id, value, onChange, label, options}: any) => {
  return (
    <FormControl variant="filled" fullWidth>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        fullWidth
        multiple
        id={id}
        value={value}
        onChange={onChange}
        label={label}
        variant="filled"
      >
        {options.map((option: any) => {
          return (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

